/* Import the Poppins font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');


/* Apply the Poppins font family to all elements */
* {
  font-family: 'Montserrat', sans-serif;  
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
.app,
header,
main,
footer,
section {

  width: 100%;
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;

}

.sectionTextImg{

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    /* flex-direction: column;   */


}

.sectionTextImgQuemSomos{

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  
  /* flex-direction: column;   */


}




.mainTexto{

    width: 100%;    
    
    height: 100%;

    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    
    
    
}

.textImg2{
  min-height: 450px;
}




.mainTexto iframe{
  width: 100%;
  height: 550px;
  object-fit: fill;
}


.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* Proporção 16:9 */
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.divNoticiasHome .swiperMaster{

  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #f08528;
  flex-direction: column;
  margin: 0 auto;




}


.divNoticiasHome{
  margin: 20px 0;
  height: 400px;



}

.divNoticiasHome h1{
  padding-bottom: 20px;



}


.divReportagens{

  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  
  

  
  

}

.divReportagens .swiperMaster{
  
  width: 100%;
  display: flex;
  gap: 30px;
  
  
  

}


.videoCard{
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-evenly;
  
  border-radius: 10px;
  border: 2px solid#f08528;
  flex: 1;
  
  margin: 0 20px;
  
  
  padding-bottom: 30px;
  background-color: white;

  
  
  
 



}

.videoCard iframe{
  width: 100%;
  height: 300px;
  border-radius: 10px 10px 0px 00px;
 
}

.videoCard h3{
  padding: 10px;
}



.mainTexto p{

  width: 80%;
  text-align: justify;
  margin: 0 auto;
  font-weight: 500; /* Negrito */
  font-size: 20px;
  
  
}

.swiperSlide{

    
    width: 50%;  

    
    
}

.swiperSlideQuemSomos{

    
  width: 100%;  

  
  
}





.swiperSlideDivVideo{

    
  width: 50%;  

  
  
}


.swipeImg img{

  width: 100%;
	height: 100%;
	object-fit: contain;
  
    
    
}

.swiperImg{
  background-color: black;
  align-items: center;

  
}

.spaceLine, .spaceLineNav{
  background-image: linear-gradient(to right, #ffd551, #f08528);
  width: 90%;
  height: 3px;
  margin: 0 auto;
}

.spaceLineNav{

  width: 100%;
}

.reverse{
  flex-direction: row-reverse;
}



header {

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 0 2rem;
  background-color: var(--bgDark);

  border-bottom: 2px solid var(--verdeClaro); /* largura e estilo da linha */

  font-weight: 500;
  font-style: normal;
  

  

}

nav a {

  margin: 0 2rem;
  
  text-decoration: none;
  font-size: 1.3rem;
  

}


nav a:hover {


  

  font-weight: bold;

  

  
  cursor: pointer;



  background-image: linear-gradient(to right, #ffbf00, #ff9c00);
   /* Aplica o degradê apenas ao texto */
   background-clip: text;
   /* Cor do texto transparente para mostrar o degradê */
   -webkit-text-fill-color: transparent;

   
}

nav a{

  font-weight: bold;

  color: black;
}

header img {

  width: 90px;



}

header .nav-btn {

  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--verdeClaro);
  display: none;
  opacity: 0;
  font-size: 1.5rem;



}



/* problemas */


.sectionLocation {
  display: flex; /* Alinha os elementos lado a lado */
  justify-content: center;
  align-items: center;
  height: 100vh;
  
}

.iframeLocalizacao, .locationImgDiv {
  flex: 1; /* Faz com que cada elemento ocupe o mesmo espaço */
  height: 100%; /* Define a altura para 100% */
  width: 100%; /* Define a largura para 100% */
}

.locationImgDiv img {
  width: 100%; /* Faz a imagem preencher completamente a largura da div */
  height: 100%; /* Faz a imagem preencher completamente a altura da div */
  object-fit: cover; /* Faz a imagem cobrir toda a div, cortando o excesso se necessário */
  object-position: top left;
}

.slideOxe{
  height: 100%;
  background-color: brown;
}


.locationImgDiv{
  background-color: aquamarine;
}

.locationImgDiv div{
  height: 100%;
}


.fade-text {
  animation: fade 1s ease-in-out forwards;
  
}

.fontText{
  font-family: 'Pacifico', cursive;
  letter-spacing: 7px;
}

@keyframes fade {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.mainTexto .mainSomos{
  font-size: 80px;
  margin-left: 10px;
  letter-spacing: -4px;
  line-height:100px;
  margin-top: -10px;
  
  

  
   

  
}



.mainTexto .mainSomos .fontText{
  
  letter-spacing: 0px;
  font-size: 92px; 

   /* Define o degradê linear */
   background-image: linear-gradient(to right, #ffbf00, #ff9c00);
   /* Aplica o degradê apenas ao texto */
   background-clip: text;
   /* Cor do texto transparente para mostrar o degradê */
   -webkit-text-fill-color: transparent;
   margin-top: 50px;
   

  
}

.sectionTextImg .mainTexto img{

  width: 130px;
  height: 130px;
  object-fit: scale-down;


}


.parceirosSection, .sectionNoticias{

  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(to right, #ffd551, #f08528);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

}


.sectionNoticias{
  background-image: None;
  min-height: auto;

}

.containerParceiros{

  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  
  



}

.parceirosSection .containerParceiros img,
.parceirosSection .parceirosTituloLogo .bbPixParceiro .bbParceiro img{
  width: 95px;
  
  transition: transform 0.3s ease-in-out;
  object-fit: scale-down;
  
  
}

.parceirosSection .containerParceiros img:hover,
.parceirosSection .parceirosTituloLogo .bbPixParceiro .bbParceiro img:hover
{

  transform: scale(1.3);

}

.parceirosSection .parceirosTituloLogo .bbPixParceiro .bbParceiro p{
  font-weight: bold;
  font-size: large;
  text-align: center;
}

.parceirosSection .parceirosTituloLogo .bbPixParceiro .bbParceiro{

  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;


}

.parceirosSection .parceirosTituloLogo .bbPixParceiro{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 100px;
}




.parceirosTituloLogo{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: white;
  width: 85%;
  border-radius: 30px;
  padding: 20px 05px;
  margin: 10px 0;

}

.h1SectionTitle{
  font-size: 1.8em;
}

.visitaP{
  font-weight: bold;

}

.visitaP a{
  color: #f08528;

}

.visitaDiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin: 10px 0;
}

.container-sociais img {
  width: 27px;

}

.sectionTextImg .mainTexto .container-sociais img {
  width: 35px;
  height: 35px;

  transition: transform 0.3s ease;
  

}

.sectionTextImg .mainTexto .container-sociais img:hover {
  transform: scale(1.1); /* Zoom de 10% */
}


.container-sociais {

  display: flex;

  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 15px;



}

footer {
  /* Altura do elemento. */
  min-height: 70px;
  background-image: linear-gradient(to right, #f0aa28, #f08528);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  


}


.pFooter {
  font-size: 18px;
  color: white;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
  padding: 0px;

  text-align: center;



}

.sectionTextImg .mainTexto .imgLogoProject{
  width: 300px;
  height: 300px;
  object-fit: scale-down;
}

.sectionTextImg .mainTexto .quemsomosh1{

  font-size: larger;

}




main .boletimSection{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
}

main .boletimSection iframe{

  border-radius: 30px;

  

  
  
}



main .selectBoletim{

  width: 400px;
  height: 50px;
  font-size: 20px;
  background-color: white;
  border: 1px solid #f08528;
  /* Outros estilos que você desejar */
  border-radius: 5px;

  margin-top: 30px;

  color: #ff9c00;

  font-weight: bolder;

  

  

  
  
}


.mainBoletim{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.mainQuemSomos{

  display: flex;
  flex-direction: column;
  
  align-items: center;

  justify-content: space-around;

  align-content: space-between;
  text-align: center;

  
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;

  gap: 30px;
  


}


.transparencia{

  display: flex;
  flex-direction: column;
  
  align-items: center;

  justify-content: space-around;

  align-content: space-between;
  text-align: center;

  
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;

  gap: 30px;



  
}

.mainColaboradores{
  width: 100%;  
  
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  justify-content: center;
  align-items: center;

}



.mainInscricao{

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  
  min-height: 100dvh;

}

.mainInscricao img{

 width: 100%;
 


}

.containerCardColaborador{
  width: 400px;
  height: auto;
 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  

  
}

.containerCardColaborador img{
  width: 400px;  /* Define a largura da imagem */
  height: auto;
 
 
  object-fit: contain;
  margin-bottom: 0px;

}

.containerCardColaborador .colaboradorInfo {
  position: absolute; /* Posiciona o h1 sobre a imagem */
  bottom: 0px; /* Ajusta a posição do h1 do fundo da imagem (ajuste conforme necessário) */
 
  background-color: #ffffff; /* Fundo semitransparente para melhorar a legibilidade */
  border: 2px solid #ff9c00;
  border-radius: 10px;
  color: white; /* Cor do texto */
  padding: 10px; /* Espaçamento interno */
  margin: 0; /* Remove margens padrão */
  text-align: center; /* Centraliza o texto dentro do h1 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  
  width: 90%;
  height: 80px;
}

.containerCardColaborador .colaboradorInfo h1{
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
}

.containerCardColaborador .colaboradorInfo h2{
  color: #ff9c00;
  font-size: 1.2rem;
  font-weight: bold;
}

.containerCardColaborador .colaboradorInfo .linhaColaborador{
  background-color: #ff9c00;
  width: 90%;
  height: 1px;
}

.inscricaoCard{
  width: 100%;
  height: auto;
}

.inscricaoCard img{
  width: 100%;
  
}

.TextoMaisSelecionarImg{

  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  padding: 10px 0;




}


.TextoMaisSelecionarImg .mainPicturesSelected{

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 50px;
  


}


.mainPicturesSelected img{

  width: 150px;
  height: 150px;
  object-fit: contain;
  cursor: pointer;
  

  


}

.mainPicturesSelected img.selected{

  border-bottom: 4px solid #ff9c00;
   


}






.mainTodasNoticias{
  width: 100%;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  
}


.mainTodasNoticias .divTodasNoticias{
  width: 90%;
  
  
  flex: 1;
  /*O flex: 1 é uma propriedade poderosa do CSS que, quando aplicado a um elemento dentro de um container flex, faz com que ele ocupe todo o espaço disponível tanto na direção principal quanto na transversal.*/
  border: 2px solid #f1892a;

  border-radius: 5px;
  
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 10px;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 5px;
  

}

.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #f1892a; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}


.spinnerButton {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7); /* Fundo semi-transparente */
}

.spinnerButton div {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #f1892a; /* Orange */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}






/* Adicione isso ao seu arquivo style.css */

/* Adicione isso ao seu arquivo style.css */

.containerCardNoticiaEdicao {
  position: relative;
  width: 100%;
  max-width: 500px; /* Card maior */
  height: 350px; /* Altura maior */
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  cursor: pointer; /* Muda o cursor para indicar que é clicável */
  padding: 0; /* Garante que não há padding */
  margin: 0; /* Garante que não há margin */
}


.containerCardNoticiaHome {
  position: relative;
  width: 100%;
  max-width: 700px; /* Card maior */
  height: 350px; /* Altura maior */
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  cursor: pointer; /* Muda o cursor para indicar que é clicável */
  padding: 0; /* Garante que não há padding */
  margin: 0; /* Garante que não há margin */
}

.containerCardNoticiaEdicao:hover, .containerCardNoticiaHome:hover {
  transform: scale(1.05); /* Efeito de zoom no hover */
}

.cardImagemContainer {
  position: absolute; /* Cobre todo o espaço do card */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0; /* Garante que não há padding */
  margin: 0; /* Garante que não há margin */
}

.cardImagem {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Faz a imagem cobrir todo o espaço */
  transition: transform 0.3s ease;
}

.containerCardNoticiaEdicao:hover .cardImagem {
  transform: scale(1.1); /* Efeito de zoom na imagem */
}

.cardOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); /* Overlay gradiente */
}

.tituloCardNoticia {
  color: white;
  font-size: 1.8rem; /* Título maior */
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


.mainNoticia{

	width: 100%;
  min-width: 320px;
  max-width: 1220px;
  margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  


}

.mainNoticia div{
  flex: 1;
  
}

.mainNoticia img{
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;


}

.mainNoticia h1{
	margin: 10px auto;
	text-align: center;
	background-color: #fff;
}

.mainNoticia p{

	font-size: 17px;
    line-height: 20px;
	text-align: justify;
	background-color: #fff;
	width: 95%;
}

/* Contêiner principal da página */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Estilo da main */
.main-noticia {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: justify;
}

/* Estilo do contêiner do conteúdo */
.content-container {
  display: flex;
  flex-direction: column;
 
  gap: 20px;
  width: 100%;
  min-width: 320px;
  max-width: 1220px;
  width: 100%;
  
}

/* Estilo da imagem de capa */
.capa-imagem {
  width: 100%;
  border-radius: 8px;
  max-width: 1200px;
  height: 420px;
  object-fit: cover;
  object-position: top left;

}

/* Estilo do título */
.titulo-noticia {
  text-align: center;
  width: 100%; /* 100% da div pai/
  
  word-wrap: break-word; /* Quebra palavras longas */
  overflow-wrap: break-word; /* Alternativa moderna para word-wrap */
  white-space: normal;

  
}

.descricao-noticia{
  font-size: 18px;
  line-height: 25px;
	text-align: justify;
}

/* Estilo do carrossel de imagens */
.carrossel-imagens {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: center;
  overflow-x: scroll;
}

/* Estilo das imagens do carrossel */
.imagem-carrossel {
  max-width: 315px;
  height: auto;
  border-radius: 8px;
  margin: 0 10px;
}

/* Estilo do autor e data */
.autor-data {
  text-align: center;
}

/* Estilo do spinner */










@media only screen and (max-width: 1024px) {

  .sectionTextImg{

    flex-direction: column-reverse;
    justify-content: space-around;
    min-height: 0px;
    padding-bottom: 5px;
    

  }

  
  .mainTexto{

    width: 100%;
    
  
    display: flex;

    flex-direction: column;
    justify-content: center;
    gap: 10px;
    
    
  }

  .swiperSlide{

    
    width: 100%;  

    
    
  }

  
  .mainTexto p{

    width: 95%;
    
    
  }

  .sectionTextImg .mainTexto .mainSomos{

    font-size: 50px;
    line-height: 64px;
    letter-spacing: 0px; 
    
    

    
  }

  .sectionTextImg .mainTexto .mainSomos .fontText{
    
    letter-spacing: 0px;

    font-size: 55px;

   

    

    
  }

  
  

  header .nav-btn {
        
    display: block;
    opacity: 1;
  
  }

  header nav {

      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      background-color: white;
      
      transition: 1s;
      transform: translateY(-100vh);
      z-index: 999;


  }

  header .responsive_nav{

      transform: none;        

  }

  header .nav-close-btn{
      position: absolute;
      top: 2rem;
      right: 2rem;


  }

  nav a{
      font-size: 1.5rem;
  }


  header img {

    width: 80px;
  
  
  
  }

  .sectionLocation{

    flex-direction: column-reverse;
  }

  .parceirosSection .parceirosTituloLogo .bbPixParceiro{
    flex-direction: column;
    gap: 20px;
  }

  .pFooter {


    width: 90%
  }

  footer {
    /* Altura do elemento. */
    min-height: 90px;
        
  
  
  }

  .sectionTextImg .mainTexto .imgLogoProject{
    width: 220px;
    height: 220px;
    object-fit: scale-down;
  }

  .swipeImg img{

    width: 100%;
    height: 100%;
    object-fit: cover;
    
      
      
  }

  main .selectBoletim{

    width: 200px;



  }

  .sectionTextImg .mainTexto .container-sociais img {
    width: 35px;
    height: 35px;
  
    
    
  
  }

  

  .videoCard iframe{
    
    height: 200px;

  
  
  }

  

  
  
  
  
  






}



